import * as React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Typography,
  useMediaQuery,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { ArrowBack, CheckBox } from "@mui/icons-material";
import { getSingleUser } from "../../APIS/users";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { PropertyListItem } from "../../custom-components/PropertListItem";
import { PropertyList } from "../../custom-components/PropertyList";
import moment from "moment";
import { UpdatePermissionModal } from "../../custom-components/UpdatePermissionModal";

export const ShowSingleUser = () => {
  const { id } = useParams();
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const align = mdUp ? "horizontal" : "vertical";

  const {
    data: user,
    isUserLoading,
    isError,
    refetch,
  } = useQuery(["user", id], () => getSingleUser(id));

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container>
      <Box sx={{ mb: 4 }}>
        <Link
          color="textPrimary"
          href="/admin/users"
          component="a"
          sx={{
            alignItems: "center",
            display: "flex",
            width: "fit-content",
            textDecoration: "none",
          }}
        >
          <ArrowBack fontSize="small" sx={{ mr: 1 }} />
          <Typography variant="subtitle2">Users</Typography>
        </Link>
      </Box>
      <Card>
        <CardHeader title="User Details" />
        <CardContent>
          <PropertyList>
            <PropertyListItem
              align={align}
              divider
              label="Name"
              value={`${user?.firstName}${user?.lastName}` || "-----"}
            />
            <PropertyListItem
              align={align}
              divider
              label="Email"
              value={user?.email || "-----"}
            />
            <PropertyListItem
              align={align}
              divider
              label="Phone"
              value={user?.phone || "-----"}
            />
            <PropertyListItem
              align={align}
              divider
              label="Role"
              value={user?.role || "-----"}
            />
            {user?.permissions &&
            <PropertyListItem
              align={align}
              divider
              label="Permissions"
              value={
                (
                  <Box display={"flex"}>
                    <div>
                      {user?.permissions?.map((permission, index) => (
                        <Typography key={index}>
                          {permission
                            ?.split("_")
                            ?.map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        </Typography>
                      ))}
                    </div>
                    {/* <IconButton aria-label="Edit permissions" > */}
                    <EditIcon size="small" onClick={handleOpen} />
                    {/* </IconButton> */}
                  </Box>
                ) || "-----"
              }
            />}
            <PropertyListItem
              align={align}
              divider
              label="Created At"
              value={moment(user?.createdAt).format("MM-DD-YYYY") || "-----"}
            />
            <PropertyListItem
              align={align}
              label="Last Updated At"
              value={
                user?.updatedAt
                  ? moment(user?.updatedAt).format("MM-DD-YYYY")
                  : "-----"
              }
            />
          </PropertyList>
          <UpdatePermissionModal user={user} open={open} handleClose={handleClose} />
        </CardContent>
      </Card>
    </Container>
  );
};
