import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

const ToggleColorMode = ({ toggleColorMode }) => {
  const theme = useTheme();

  return (
    <IconButton sx={{ ml: 1 }} onClick={toggleColorMode}>
      {theme.palette.mode === "dark" ? (
        <Brightness7Icon style={{ color: "#fff" }}/>
      ) : (
        <Brightness4Icon style={{ color: "#fff" }} />
      )}
    </IconButton>
  );
};

export default ToggleColorMode;
