import React, { useState } from "react";
import {
  Button,
  Box,
  Typography,
  Container,
  Paper,
} from "@mui/material";
import DonationModal from "./DonationModal";

const DonationForm = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Box
      sx={{
        background: "url('/thumbnails/donation-bg.jpg') no-repeat center center/cover",
        minHeight: "80vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "2rem",
      }}
    >
      <Container component="main" maxWidth="md">
        <Paper
          elevation={6}
          sx={{
            p: 5,
            borderRadius: "20px",
            background: "rgba(255, 255, 255, 0.85)",
            boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)",
            backdropFilter: "blur(10px)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h3"
              component="h1"
              sx={{
                mb: 3,
                fontWeight: "bold",
                color: "#004d40",
                textShadow: "2px 2px 4px rgba(0,0,0,0.2)",
              }}
            >
              Illuminate Minds & Hearts
            </Typography>
            <Typography
              variant="h6"
              component="p"
              sx={{
                mb: 4,
                color: "#00796b",
                lineHeight: 1.8,
                maxWidth: "700px",
              }}
            >
              Your generous support helps us spread the light of knowledge. By donating to Islam Info, you empower countless individuals to learn and understand the beauty and truth of Islam. Join us in our mission to share knowledge and foster understanding.
            </Typography>
            <Button
              variant="contained"
              fullWidth
              sx={{
                py: 1.5,
                background: "linear-gradient(90deg, #00b09b 0%, #96c93d 100%)",
                color: "#fff",
                borderRadius: "10px",
                textTransform: "none",
                fontWeight: "bold",
                fontSize: "18px",
                boxShadow: "0 6px 15px rgba(0, 0, 0, 0.2)",
                maxWidth: "400px",
                "&:hover": {
                  background: "linear-gradient(90deg, #00b09b 0%, #96c93d 100%)",
                  opacity: 0.9,
                },
              }}
              onClick={() => setOpenModal(true)}
            >
              Become a Donor
            </Button>
          </Box>
        </Paper>
        <DonationModal open={openModal} onClose={handleClose} />
      </Container>
    </Box>
  );
};

export default DonationForm;
