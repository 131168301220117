import React from "react";

import { Box, Grid, Container, useTheme, Typography } from "@mui/material";

export const Downloads = () => {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Container
        maxWidth="xl"
        sx={{
          py: "30px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          [theme.breakpoints.up("md")]: {
            marginLeft: theme.spacing(9) + 1,
          },
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
          height={"500px"}
        >
          <Typography variant="h3" fontWeight={700}>
            No Downloads to Show
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};
