import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { toast } from "react-toastify";
import { getAllPermissionsByRole, updateUserPermissions } from "../APIS/users";
import { useQuery } from "react-query";

export const UpdatePermissionModal = ({ user, open, handleClose }) => {
  const {
    data: allPermissions,
    isPermissionsLoading,
    isError,
    refetch,
  } = useQuery(["allPermissions", "3"], () => getAllPermissionsByRole("3"));

  const [permissions, setPermissions] = useState(user?.permissions || []);

  const handleCheckboxChange = (event) => {
    const permission = event.target.name;
    if (event.target.checked) {
      setPermissions((prevPermissions) => [...prevPermissions, permission]);
    } else {
      setPermissions((prevPermissions) =>
        prevPermissions.filter((item) => item !== permission)
      );
    }
  };

  const handleUpdatePermissions = async () => {
    toast.loading("please wait");
    try {
      const response = await updateUserPermissions(permissions);
      toast.dismiss();
      if (response.status == 200) {
        handleClose();
        toast.success("Permissions updated successfully!");
      }
    } catch (e) {
      toast.error("Error", e);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Update Permissions</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {user?.permissions?.map((permission, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={permissions.includes(permission)}
                  onChange={handleCheckboxChange}
                  name={permission.name}
                />
              }
              label={permission
                ?.split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
            />
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleUpdatePermissions}>Update</Button>
      </DialogActions>
    </Dialog>
  );
};
