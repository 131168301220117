import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useNavigate, useLocation } from "react-router-dom";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import HomeIcon from "@mui/icons-material/Home";
import ListItemText from "@mui/material/ListItemText";
import OfflinePinIcon from "@mui/icons-material/OfflinePin";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import DownloadIcon from "@mui/icons-material/Download";
import HistoryIcon from "@mui/icons-material/History";
import SettingsIcon from "@mui/icons-material/Settings";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PeopleIcon from '@mui/icons-material/People';
import { IconButton, Collapse } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CategoryIcon from '@mui/icons-material/Category';
import FileUploadIcon from '@mui/icons-material/FileUpload';


const drawerWidth = 240;
const drawerTop = 70;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  borderRight: "0px !important",
  whiteSpace: "nowrap",
  top: drawerTop,
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  "& .MuiDrawer-paper": {
    ...((!open && closedMixin(theme)) || (open && openedMixin(theme))),
  },
}));

export const getRole = () => {
  const roleString = localStorage.getItem("role");
  return roleString ? JSON.parse(roleString) : null;
};

export const getPermissions = () => {
  const permissionsString = localStorage.getItem("permissions");
  return permissionsString ? JSON.parse(permissionsString) : [];
};

export const Sidebar = ({ open, handleDrawer }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [adminPanelOpen, setAdminPanelOpen] = useState(false);
  const [role, setRole] = useState(null);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    setRole(getRole());
    setPermissions(getPermissions());
  }, []);

  const listItems = [
    { text: "Home", icon: <HomeIcon />, path: "/home" },
    { text: "Shorts", icon: <VideoLibraryIcon />, path: "/shorts" },
    { text: "Offline", icon: <OfflinePinIcon />, path: "/offline" },
    { text: "History", icon: <HistoryIcon />, path: "/history" },
  ];

  const secondaryListItems = [
    { text: "Favorites", icon: <FavoriteIcon />, path: "/favorites" },
    { text: "Downloads", icon: <DownloadIcon />, path: "/downloads" },
    { text: "Settings", icon: <SettingsIcon />, path: "/settings" },
  ];

  const adminItems = [
    { text: "Users", icon: <PeopleIcon />, path: "/admin/users", permission: "users_listing" },
    { text: "Videos", icon: <VideoLibraryIcon />, path: "/admin/videos", permission: "super-video-listing" },
    { text: "Roles", icon: <AdminPanelSettingsIcon />, path: "/admin/role-and-permissions", permission: "roles_listing" },
    { text: "Upload Video", icon: <FileUploadIcon />, path: "/admin/upload-video", permission: "video_upload" },
    { text: "Tags", icon: <CategoryIcon />, path: "/admin/tags", permission: "create_tag" },
  ];

  const handleAdminPanelToggle = () => {
    setAdminPanelOpen(!adminPanelOpen);
  };

  const renderListItem = (item, nested = false) => (
    <ListItem
      key={item.text}
      disablePadding
      sx={{ display: "block", pl: nested ? 4 : 0 }}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
          borderRadius: 2,
          color: location.pathname === item.path ? '#026f02' : 'inherit',
          backgroundColor: location.pathname === item.path ? 'rgb(0 128 0 / 20%)' : 'inherit',
        }}
        onClick={() => navigate(item.path)}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
            color: location.pathname === item.path ? '#026f02' : 'inherit',
          }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={item.text}
          sx={{
            opacity: open ? 1 : 0,
            color: location.pathname === item.path ? '#026f02' : 'inherit',
            fontWeight: "500"
          }}
        />
      </ListItemButton>
    </ListItem>
  );

  const hasAdminAccess = role && (role.name === "SuperAdmin" || permissions.some(p => adminItems.some(item => item.permission === p.name)));

  return (
    <Drawer variant="permanent" open={open}>
      <Box sx={{ px: 1, py: 2 }}>
        <DrawerHeader>
          <IconButton onClick={handleDrawer}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>

        <List>
          {listItems.map((item) => renderListItem(item))}
        </List>

        <Divider sx={{ my: 2 }} />

        <List>
          {secondaryListItems.map((item) => renderListItem(item))}
          {hasAdminAccess && (
            <>
              <ListItemButton onClick={handleAdminPanelToggle}>
                <ListItemIcon>
                  <AdminPanelSettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Admin Panel" />
                {adminPanelOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={adminPanelOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {adminItems.filter(item => 
                    role.name === "SuperAdmin" || permissions.some(p => p.name === item.permission)
                  ).map((item) => renderListItem(item, true))}
                </List>
              </Collapse>
            </>
          )}
        </List>
      </Box>
    </Drawer>
  );
};