import React, { createContext, useContext, useState } from "react";

const SearchContext = createContext();

export function useSearch() {
  return useContext(SearchContext);
}

export function SearchProvider({ children }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const updateSearchQuery = (query, results) => {
    setSearchQuery(query);
    setSearchResults(results);
  };

  return (
    <SearchContext.Provider value={{ searchQuery, searchResults, updateSearchQuery }}>
      {children}
    </SearchContext.Provider>
  );
}
