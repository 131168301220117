import { UploadVideo } from "./UploadVideo";
import { fetchVideo } from "../APIS/videos";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/material";

export const EditVideo = () => {
    const { id } = useParams();

    const {
        data: video,
        isLoading: isVideoLoading,
        isError,
        error,
    } = useQuery(
        ["video", id],
        () => fetchVideo(id),
        {
            enabled: !!id,
        }
    );

    if (isVideoLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    if (isError) {
        return <div>Error loading video: {error.message}</div>;
    }

    if (!video) {
        return <div>No video found</div>;
    }

    console.log(video, "new video for edit");

    return (
        <>
            <UploadVideo isEdit={true} video={video} />
        </>
    );
};
