import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Button,
  Typography,
  Box,
  TextField,
  IconButton,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  useTheme,
  alpha,
} from "@mui/material";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ReactPlayer from "react-player";
import { categories } from "../UploadVideo";
import { updateVideo, updateVideoStatus } from "../../APIS/videos";
import { toast } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion";

const MotionBox = motion(Box);

export const VideoDetailModal = ({
  video,
  openModal,
  handleClose,
  refetch
}) => {
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(video?.name);
  const [editedTag, setEditedTag] = useState(video?.category);
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(video?.isVerified || false);
  const [duration, setDuration] = useState(0);
  const [videoUrl, setVideoUrl]=useState(video?.videos3link)

  useEffect(() => {
    setEditedTitle(video?.name)
    setEditedTag(video?.category)
    setVerified(video?.isVerified)
    setVideoUrl(video?.videos3link)
  }, [video])

  const handleEditClick = () => setIsEditing(true);

  const handleSave = async () => {
    try {
      const titleChanged = editedTitle !== video?.name;
      const categoryChanged = editedTag !== video?.category;
      const verifiedChanged = verified !== video?.isVerified;
  
      let updateResponse;
      let verifyResponse;
  
      if (titleChanged || categoryChanged) {
        const formData = new FormData();
        formData.append('name', editedTitle);
        formData.append('category', editedTag);
  
        updateResponse = await updateVideo(formData, video?.id);

        if (updateResponse?.status === 200) {
          toast.success("Video details successfully updated");
        } else {
          toast.error("Failed to update video details");
        }
      }
  
      if (verifiedChanged) {
        verifyResponse = await updateVideoStatus(video?.id, verified);
        if (verifyResponse?.affected === 1) {
          toast.success("Video verification status updated");
        } else {
          toast.error("Failed to update video verification status");
        }
      }
  
      if ((updateResponse?.status === 200 || !updateResponse) && 
          (verifyResponse?.status === 200 || !verifyResponse)) {
        toast.success("All changes saved successfully");
      }
  
      refetch();
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating video:", error);
      toast.error("An error occurred while updating the video");
    }
  };

  const handleReady = () => setLoading(false);
  const handleBuffer = () => setLoading(true);
  const handleDuration = (duration) => setDuration(duration);

  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${hours > 0 ? hours + ':' : ''}${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const gradientBg = theme.palette.mode === 'dark'
    ? 'linear-gradient(135deg, #1a1a1a 0%, #2c2c2c 100%)'
    : 'linear-gradient(135deg, #f0f0f0 0%, #ffffff 100%)';

  const buttonGradient = 'linear-gradient(45deg, #006400 30%, #32CD32 90%)';
  const buttonHoverGradient = 'linear-gradient(45deg, #005000 30%, #2AB52A 90%)';

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: {
          borderRadius: 20,
          background: gradientBg,
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
        }
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h5" sx={{ 
          color: theme.palette.mode === 'dark' ? '#FFD700' : '#006400',
          fontWeight: 'bold',
          textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
        }}>
          Video Details
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Box sx={{
            position: "relative",
            width: "100%",
            paddingTop: "56.25%", // 16:9 aspect ratio
            marginBottom: "20px",
            borderRadius: 4,
            overflow: 'hidden',
            boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
          }}>
            {loading && (
              <CircularProgress
                size={60}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: '#FFD700',
                }}
              />
            )}
            <ReactPlayer
              url={videoUrl}
              width="100%"
              height="100%"
              style={{ position: 'absolute', top: 0, left: 0 }}
              controls={true}
              muted={false}
              onReady={handleReady}
              onBuffer={handleBuffer}
              onDuration={handleDuration}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <AccessTimeIcon sx={{ color: theme.palette.mode === 'dark' ? '#FFD700' : '#006400', mr: 1 }} />
            <Typography variant="body1" sx={{ color: theme.palette.mode === 'dark' ? '#fff' : '#333' }}>
              Duration: {formatDuration(duration)}
            </Typography>
          </Box>
          <MotionBox 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            py={2}
          >
            <Box sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}>
              <Typography variant="h6" sx={{ color: theme.palette.mode === 'dark' ? '#fff' : '#006400' }}>
                {isEditing ? 'Edit Video Details' : 'Video Information'}
              </Typography>
              {!isEditing && (
                <IconButton
                  onClick={handleEditClick}
                  sx={{
                    color: theme.palette.mode === 'dark' ? '#FFD700' : '#006400',
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.success.main, 0.1),
                    },
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
            </Box>
            <AnimatePresence mode="wait">
              {!isEditing ? (
                <MotionBox
                  key="details"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <Typography variant="body1" sx={{ mb: 1, color: theme.palette.mode === 'dark' ? '#fff' : '#333' }}>
                    <strong>Title:</strong> {video?.name}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1, color: theme.palette.mode === 'dark' ? '#fff' : '#333' }}>
                    <strong>Tag:</strong> {video?.Tag || "Nimaz"}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1, color: theme.palette.mode === 'dark' ? '#fff' : '#333' }}>
                    <strong>Uploaded By:</strong> {video?.uploadedby}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1, color: theme.palette.mode === 'dark' ? '#fff' : '#333' }}>
                    <strong>Uploaded At:</strong> {moment(video?.createdAt).format("MMM Do YY")}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                    <CheckCircleIcon sx={{ color: verified ? '#32CD32' : '#ccc', mr: 1 }} />
                    <Typography variant="body1" sx={{ color: theme.palette.mode === 'dark' ? '#fff' : '#333' }}>
                      {verified ? 'Verified' : 'Not Verified'}
                    </Typography>
                  </Box>
                </MotionBox>
              ) : (
                <MotionBox
                  key="edit-form"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <TextField
                    label="Title"
                    value={editedTitle}
                    onChange={(e) => setEditedTitle(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: theme.palette.mode === 'dark' ? '#FFD700' : '#006400',
                        },
                        '&:hover fieldset': {
                          borderColor: theme.palette.mode === 'dark' ? '#FFD700' : '#006400',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: theme.palette.mode === 'dark' ? '#FFD700' : '#006400',
                        },
                      },
                    }}
                  />
                  <Autocomplete
                    options={categories.map((option) => option.label)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tag"
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: theme.palette.mode === 'dark' ? '#FFD700' : '#006400',
                            },
                            '&:hover fieldset': {
                              borderColor: theme.palette.mode === 'dark' ? '#FFD700' : '#006400',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: theme.palette.mode === 'dark' ? '#FFD700' : '#006400',
                            },
                          },
                        }}
                      />
                    )}
                    value={editedTag}
                    onChange={(event, newValue) => {
                      setEditedTag(newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setEditedTag(newInputValue);
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={verified}
                        onChange={(e) => setVerified(e.target.checked)}
                        sx={{
                          color: theme.palette.success.main,
                          '&.Mui-checked': {
                            color: theme.palette.success.main,
                          },
                        }}
                      />
                    }
                    label="Verified"
                  />
                </MotionBox>
              )}
            </AnimatePresence>
          </MotionBox>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        {isEditing ? (
          <>
            <Button
              onClick={handleSave}
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#fff",
                background: buttonGradient,
                borderRadius: '50px',
                px: 4,
                '&:hover': {
                  background: buttonHoverGradient,
                },
              }}
            >
              Save
            </Button>
            <Button
              onClick={() => setIsEditing(false)}
              variant="outlined"
              sx={{
                textTransform: "none",
                color: theme.palette.mode === 'dark' ? '#FFD700' : '#006400',
                borderColor: theme.palette.mode === 'dark' ? '#FFD700' : '#006400',
                borderRadius: '50px',
                px: 4,
                '&:hover': {
                  borderColor: theme.palette.mode === 'dark' ? '#FFA500' : '#004D00',
                  backgroundColor: alpha(theme.palette.success.main, 0.1),
                },
              }}
            >
              Cancel
            </Button>
          </>
        ) : (
          <Button
            onClick={handleClose}
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#fff",
              background: buttonGradient,
              borderRadius: '50px',
              px: 4,
              '&:hover': {
                background: buttonHoverGradient,
              },
            }}
            autoFocus
          >
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};