import React from "react";
import { CreateUser } from "./CreateUser";
import { getSingleUser } from "../../APIS/users";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

export const EditUser = () => {
    const { id } = useParams();

    const {
        data: user,
        isUserLoading,
        isError,
        refetch,
      } = useQuery(["user", id], () => getSingleUser(id));

    return <CreateUser isEdit={true} data={user}/>
};
