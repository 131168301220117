import React from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Box, Typography, Divider } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

const CheckoutForm = ({ amount, donorInfo }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: donorInfo.name,
        email: donorInfo.email,
      },
    });

    if (error) {
      console.error(error);
    } else {
      console.log("PaymentMethod:", paymentMethod);
      // Handle the payment method further on the backend
    }
  };

  return (
    <Box
    sx={{
      background: "url('/thumbnails/donation-bg.jpg') no-repeat center center/cover",
      minHeight: "80vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2rem",
    }}
  >
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: 500,
        margin: "auto",
        padding: 3,
        borderRadius: 2,
        boxShadow: 2,
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
        Secure Checkout
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
        Please enter your payment details below to complete your donation.
      </Typography>
      <Divider sx={{ width: "100%", mb: 3 }} />
      <Box sx={{ width: "100%", mb: 3 }}>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "16px",
                color: "#424770",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
          }}
        />
      </Box>
      <Button
        sx={{
          mt: 2,
          background: "linear-gradient(90deg, #00b09b 0%, #96c93d 100%)",
          color: "#fff",
          textTransform: "none",
          "&:hover": {
            background: "linear-gradient(90deg, #00b09b 0%, #96c93d 100%)",
            opacity: 0.8,
          },
        }}
        variant="contained"
        fullWidth
        type="submit"
        disabled={!stripe}
      >
        Pay ${amount}
      </Button>
    </Box>
    </Box>
  );
};

export default CheckoutForm;
