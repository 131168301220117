import React, { useState } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Link,
  useTheme,
  styled,
} from "@mui/material";
import {
  deletePermission,
  getAllRoles,
  deleteRole,
} from "../../APIS/roleAndPermission";
import { getAllPermissions } from "../../APIS/users";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { WarningModal } from "../../custom-components/WarningModal";
import { ListTable } from "../../custom-components/ListTable";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { yellowGradient } from "../../Home";

const MotionContainer = motion(Container);
const MotionTypography = motion(Typography);
const MotionButton = motion(Button);

const TabButton = styled(Button)(({ theme, active }) => ({
  borderRadius: '20px',
  margin: '0 10px',
  padding: '10px 20px',
  // backgroundColor: active ? theme.palette.success.main : theme.palette.grey[300],
  background: active ? yellowGradient : theme.palette.grey[300],
  color: active ? theme.palette.success.main : theme.palette.text.primary,
  '&:hover': {
    backgroundColor: active ? theme.palette.success.dark : theme.palette.grey[400],
  },
}));

const RolePermissionList = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [permissionId, setPermissionId] = useState("");
  const [roleId, setRoleId] = useState("");
  const [warningModal, setWarningModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const {
    data: roles,
    isLoading: rolesLoading,
    refetch: refetchRoles,
  } = useQuery("roles", getAllRoles);
  const {
    data: permissions,
    isLoading: permissionsLoading,
    refetch: refetchPermissions,
  } = useQuery("permissions", getAllPermissions);

  const handleTabChange = (newValue) => {
    setSelectedTab(newValue);
  };

  const handleCreateRole = () => {
    navigate("create");
  };

  const handleWarningModal = (id, isRole) => {
    setWarningModal(true);
    if (isRole) {
      setRoleId(id);
      setPermissionId("");
    } else {
      setPermissionId(id);
      setRoleId("");
    }
  };

  const handleCloseWarningModal = () => {
    setWarningModal(false);
  };

  const handleDelete = async () => {
    toast.loading("Deleting ...");
    try {
      const response = roleId
        ? await deleteRole(roleId)
        : await deletePermission(permissionId);

      toast.dismiss();
      handleCloseWarningModal();
      toast.success("Record has been successfully deleted");
      refetchPermissions();
      refetchRoles();
    } catch (error) {
      toast.dismiss();
      toast.error("Error deleting record: " + error.message);
    }
  };

  const roleDataArray = roles?.map((record) => [
    {
      title: "Name",
      value: (
        <Link
          href={`/admin/users/${record.id}`}
          style={{ textDecoration: "none", color: theme.palette.success.main }}
        >
          {record?.name}
        </Link>
      ),
    },
  ]);

  const permissionDataArray = permissions?.map((record) => [
    {
      title: "Name",
      value: record?.name,
    },
  ]);

  return (
    <MotionContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      maxWidth="xl"
    >
      <Box sx={{ 
        py: 4, 
        display: "flex", 
        justifyContent: "space-between",
        alignItems: "center",
        background: `linear-gradient(45deg, ${theme.palette.success.light} 30%, ${theme.palette.success.main} 90%)`,
        borderRadius: '15px',
        padding: '20px',
        marginBottom: '20px',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)'
      }}>
        <MotionTypography 
          variant="h3" 
          fontWeight={700}
          initial={{ x: -50 }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 120 }}
          sx={{
            color: theme.palette.common.white,
            textShadow: '2px 2px 4px rgba(0,0,0,0.5)'
          }}
        >
          Roles and Permissions
        </MotionTypography>
        <MotionButton
          variant="contained"
          initial={{ x: 50 }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 120 }}
          onClick={handleCreateRole}
          sx={{
            borderRadius: '50px',
            color: theme.palette.common.white,
            backgroundColor: theme.palette.success.dark,
            textTransform: "none",
            padding: '10px 20px',
            "&:hover": {
              backgroundColor: theme.palette.success.main,
              boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)'
            },
          }}
        >
          Create Role and Permission
        </MotionButton>
      </Box>
      <Box sx={{ 
        display: 'flex',
        justifyContent: 'center',
        mb: 3,
      }}>
        <TabButton
          active={selectedTab === 0}
          onClick={() => handleTabChange(0)}
        >
          Roles
        </TabButton>
        <TabButton
          active={selectedTab === 1}
          onClick={() => handleTabChange(1)}
        >
          Permissions
        </TabButton>
      </Box>
      <Box sx={{ mt: 3 }}>
        {selectedTab === 0 && (
          <ListTable
            data={roleDataArray}
            isLoading={rolesLoading}
            error="No roles exist"
            onDelete={(record) => handleWarningModal(record.id, true)}
            permissions={{
              canDelete: true, // Replace with actual permission check
            }}
          />
        )}
        {selectedTab === 1 && (
          <ListTable
            data={permissionDataArray}
            isLoading={permissionsLoading}
            error="No permissions exist"
            onDelete={(record) => handleWarningModal(record.id, false)}
            permissions={{
              canDelete: true, // Replace with actual permission check
            }}
          />
        )}
      </Box>
      <WarningModal
        content="Are you sure you want to delete this record?"
        openModal={warningModal}
        handleClose={handleCloseWarningModal}
        handleDelete={handleDelete}
      />
    </MotionContainer>
  );
};

export default RolePermissionList;