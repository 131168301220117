import { axiosInstance } from "../../AxiosInstance";

export const createRoleandPermission = async (data) => {
  try {
    const response = await axiosInstance.post("users/createRWP", data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("authToken"),
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  } catch (error) {
    console.log("Error creating user:", error.response);
  }
};

export const getAllRoles = async () => {
  try {
    const response = await axiosInstance.get("users/allroles");

    return response.data;
  } catch (error) {
    console.error("Error fetching roles and permissions:", error);
  }
};

export const getAllPermissions = async () => {
  try {
    const response = await axiosInstance.get("users/allpermissions")

    return response.data;
  } catch (error) {
    console.error("Error fetching roles and permissions:", error);
  }
};


export const deletePermission = async (id) => {
  try {
    const response = await axiosInstance.delete(`/users/deletePermission/${id}`, {
      headers: {
        Authorization: "Bearer" + localStorage.getItem("authToken"),
      },
    });

    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error deleting user:", error);
  }
};


export const deleteRole = async (id) => {
  try {
    const response = await axiosInstance.delete(`/users/deleteRole/${id}`, {
      headers: {
        Authorization: "Bearer" + localStorage.getItem("authToken"),
      },
    });

    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error deleting role:", error);
  }
};
