import React from "react";
import {
  FormControlLabel,
  Switch,
  MenuItem,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Box,
  CardHeader,
  styled,
} from "@mui/material";
import { useFormik } from "formik";
import { toast } from "react-toastify";

const Title = styled("h2")`
  margin-bottom: 16px;
  text-align: center;
  color: #009900;
`;

const AccountSettings = () => {
  const formik = useFormik({
    initialValues: {
      notificationsEnabled: true,
      videoFrequency: "daily",
      noOfvideosToWatch: "2",
      topic: "Random",
      category: "ahadees",
    },
    onSubmit: (values) => {
      toast.loading("please wait..");
      console.log(values);

      setTimeout(() => {
        toast.dismiss();
      }, 2000);
    },
  });

  return (
    <Card>
      <CardContent>
        <Title>Update Account Settings</Title>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                select
                label="Select a category"
                name="category"
                value={formik.values.category}
                onChange={formik.handleChange}
                fullWidth
              >
                <MenuItem value="ahadees">Ahadees</MenuItem>
                <MenuItem value="ayat">Quran Aya</MenuItem>
                <MenuItem value="tafseer">Tafseer</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Topic to watch video"
                name="topic"
                value={formik.values.topic}
                onChange={formik.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Number of videos to watch"
                name="noOfvideosToWatch"
                value={formik.values.noOfvideosToWatch}
                onChange={formik.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                label="Set Video Frequency"
                name="videoFrequency"
                value={formik.values.videoFrequency}
                onChange={formik.handleChange}
                fullWidth
              >
                <MenuItem value="daily">Daily</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.notificationsEnabled}
                    onChange={formik.handleChange}
                    name="notificationsEnabled"
                  />
                }
                label="Enable notifications for new videos"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    marginY: 2,
                    backgroundColor: "#009900",
                    textTransform: "none",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "darkgreen",
                    },
                  }}
                >
                  Update
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default AccountSettings;
