import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4CAF50', // Green
      dark: '#2E7D32', // Dark Green
    },
    secondary: {
      main: '#FFD54F', // Yellow
      light: '#FFECB3', // Light Yellow
    },
    background: {
      default: '#FFFFFF', // White
      paper: '#F5F5F5', // Light Gray
    },
    text: {
      primary: '#212121', // Almost Black
      secondary: '#424242', // Dark Gray
    },
  },
  gradients: {
    primary: 'linear-gradient(45deg, #4CAF50 30%, #2E7D32 90%)',
    secondary: 'linear-gradient(45deg, #FFD54F 30%, #FFC107 90%)',
    background: 'linear-gradient(135deg, #FFFFFF 0%, #F5F5F5 50%, #E8F5E9 100%)',
    darkGreen: 'linear-gradient(45deg, #2E7D32 30%, #1B5E20 90%)',
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: 700,
      color: '#2E7D32', // Dark Green
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '25px',
          textTransform: 'none',
          fontWeight: 'bold',
          padding: '12px 24px',
        },
        containedPrimary: {
          background: 'linear-gradient(45deg, #4CAF50 30%, #2E7D32 90%)',
          '&:hover': {
            background: 'linear-gradient(45deg, #2E7D32 30%, #1B5E20 90%)',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#4CAF50',
            },
            '&:hover fieldset': {
              borderColor: '#2E7D32',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#2E7D32',
            },
          },
          '& .MuiInputLabel-root': {
            color: '#4CAF50',
            '&.Mui-focused': {
              color: '#2E7D32',
            },
          },
        },
      },
    },
  },
});

export default theme;