import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  TextField,
  MenuItem,
  Select,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

const DonationModal = ({ open, onClose }) => {
    const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      frequency: "monthly",
      selectedAmount: "",
      amount: "",
      currency: "USD",
    },
    onSubmit: (values) => {
        navigate('/checkout')

    },
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth borderRadius='10px' >
      <DialogTitle  sx={{ position: "relative" }}>
        <Box sx={{ textAlign: "center", mb: 2 }}>
          <img src="/logo.png" alt="Logo" style={{ maxWidth: "80px" }} />
        </Box>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{ position: "absolute",  top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ToggleButtonGroup
          value={formik.values.frequency}
          exclusive
          onChange={(e, value) => formik.setFieldValue("frequency", value)}
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 2,
            "& .MuiToggleButton-root": {
              flex: 1,
              borderRadius: "20px",
              textTransform: "none",
              fontSize: "16px",
              color: "#000",
              border: "none",
              backgroundColor: "#E3F2FD",
              "&.Mui-selected": {
                backgroundColor: "green",
                color: "#fff",
              },
              "&:not(:first-of-type)": {
                borderLeft: "1px solid #fff",
              },
            },
          }}
        >
          <ToggleButton value="one-time">One time</ToggleButton>
          <ToggleButton value="monthly">Monthly</ToggleButton>
        </ToggleButtonGroup>
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: 2,
              mb: 2,
            }}
          >
            {["500", "100", "50", "25"].map((amount) => (
              <Button
                key={amount}
                variant={
                  formik.values.selectedAmount === amount
                    ? "contained"
                    : "outlined"
                }
                onClick={() => {
                  formik.setFieldValue("selectedAmount", amount);
             
                }}
                sx={{
                  borderRadius: "10px",
                  color:
                    formik.values.selectedAmount === amount
                      ? "#fff"
                      : "inherit",
                  background:
                    formik.values.selectedAmount === amount
                      ? 'linear-gradient(90deg, #00b09b 0%, #96c93d 100%)'
                      : "#e0faf0",
                  "&:hover": {
                    backgroundColor:
                      formik.values.selectedAmount === amount
                        ? "#00796b"
                        : "#b2f2e4",
                  },
                  fontSize: "16px",
                }}
              >
               {formik.values.currency === "USD"
                      ? "$"
                      : formik.values.currency === "EUR"
                      ? "€"
                      : "£"}{amount}
              </Button>
            ))}
          </Box>
          <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
            <Select
              value={formik.values.currency}
              onChange={(e) => formik.setFieldValue("currency", e.target.value)}
              sx={{ minWidth: "100px", backgroundColor: "#e0faf0" }}
            >
              <MenuItem value="USD">USD</MenuItem>
              <MenuItem value="EUR">EUR</MenuItem>
              <MenuItem value="GBP">GBP</MenuItem>
            </Select>
            <TextField
              fullWidth
              placeholder="Enter other amount"
              value={formik.values.amount}
              onChange={formik.handleChange}
              // onMouseEnter={formik.setFieldValue("selectedAmount", 0)}
              name="amount"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {formik.values.currency === "USD"
                      ? "$"
                      : formik.values.currency === "EUR"
                      ? "€"
                      : "£"}
                  </InputAdornment>
                ),
                style: { backgroundColor: "#e0faf0", borderRadius: "10px" },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                },
              }}
            />
          </Box>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            sx={{
              mt: 2,
              background: 'linear-gradient(90deg, #00b09b 0%, #96c93d 100%)',
              color: "#fff",
              textTransform: "none",
              "&:hover": {
                background: 'linear-gradient(90deg, #00b09b 0%, #96c93d 100%)',
                opacity: 0.8,
              },
              borderRadius: "10px",
              fontSize: "16px",
            }}
          >
            Continue
          </Button>
        </Box>
        <Box sx={{ textAlign: "center", mt: 2 }}>
          <Button
            variant="text"
            sx={{ textTransform: "none", color: "green" }}
          >
            FAQs
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DonationModal;
