import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import LayoutContainer from "./layout/LayoutContainer";


export const authToken = localStorage.getItem("authToken");
export const ProtectedRoutes = () => {
  return (
    <>
      <LayoutContainer>
        <Outlet />
      </LayoutContainer>
    </>
  );
  //   : (
  // =
  //     <Navigate to="/login" replace />
  //   );
};
