import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Grid,
  TextField,
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createUser, updateUserRole } from "../../APIS/users";
import { toast } from "react-toastify";
import { updateUser } from "../../APIS/users";

export const CreateUser = ({ isEdit = false, data }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (data) {
      setUser(data);
    }
  }, [data]);

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("First Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string().required("Phone is required"),
    role: Yup.string().required("Role is required"),
    ...(() => {
      if (!isEdit) {
        return {
          password: Yup.string().required("Password is required"),
          confirmPassword: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("Confirm Password is required"),
        };
      }
      return {};
    })(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: user?.firstName || "",
      lastname: user?.lastName || "",
      email: user?.email || "",
      password: user?.password || "",
      confirmPassword: user?.password || "",
      phone: user?.phone || "",
      role: user?.role || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload = {
        ...values,
        ...(values.password && { password: values.password }),
      };

      try {
        setLoading(true);
        if (isEdit) {
          const response = await updateUser(payload, user?.id);
          if (response) {
            toast.success("User has been updated successfully!");
            navigate("/admin/users");
          }
        } else {
          const response = await createUser(payload);
          if (response) {
            toast.success("User has been created successfully!");
            navigate("/admin/users");
          }
        }
      } catch (error) {
        console.error("Error creating user:", error);
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Container>
      <Card>
        <CardContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <Typography variant="h4" sx={{ fontWeight: 700 }}>
                  {isEdit ? "Edit user details" : "Create new user"}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="First Name"
                  variant="outlined"
                  name="firstname"
                  value={formik.values.firstname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.firstname && Boolean(formik.errors.firstname)
                  }
                  helperText={
                    formik.touched.firstname && formik.errors.firstname
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Last Name"
                  variant="outlined"
                  name="lastname"
                  value={formik.values.lastname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.lastname && Boolean(formik.errors.lastname)
                  }
                  helperText={formik.touched.lastname && formik.errors.lastname}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  type="email"
                  variant="outlined"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Phone"
                  variant="outlined"
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </Grid>
              {!isEdit && (
                <>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Password"
                      type="password"
                      variant="outlined"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Confirm Password"
                      type="password"
                      variant="outlined"
                      name="confirmPassword"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.confirmPassword &&
                        Boolean(formik.errors.confirmPassword)
                      }
                      helperText={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                      }
                    />
                  </Grid>
                </>
              )}

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Role"
                  select
                  variant="outlined"
                  name="role"
                  value={formik.values.role}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.role && Boolean(formik.errors.role)}
                  helperText={formik.touched.role && formik.errors.role}
                >
                  <MenuItem value="User">User</MenuItem>
                  <MenuItem value="Teacher">Teacher</MenuItem>
                  <MenuItem value="SuperAdmin">Admin</MenuItem>
                  <MenuItem value="Student">Student</MenuItem>
                  <MenuItem value="Quality-Assurance">
                    Quality Assurance
                  </MenuItem>
                  <MenuItem value="Senior-Quality-Assurance">
                    Senior Quality Assurance
                  </MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <CardActions
              sx={{ display: "flex", justifyContent: "end", marginTop: 2 }}
            >
              <Button
                type="button"
                variant="outlined"
                sx={{
                  color: "#009900",
                  borderColor: "#009900",
                  textTransform: "none",
                  "&:hover": {
                    borderColor: "#009900",
                  },
                }}
                onClick={() => {
                  navigate("/admin/users");
                  formik.handleReset();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                onClick={formik.handleSubmit}
                sx={{
                  backgroundColor: "#009900",
                  textTransform: "none",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "darkgreen",
                  },
                }}
              >
                {loading ? (
                  <CircularProgress color="success" />
                ) : isEdit ? (
                  "Update"
                ) : (
                  "Create"
                )}
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};
