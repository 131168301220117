import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Box,
  Container,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { styled, keyframes } from "@mui/material/styles";
import { createRoleandPermission } from "../../APIS/roleAndPermission";
import { toast } from "react-toastify";
import LabelIcon from "@mui/icons-material/Label";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { createVideoTag } from "../../APIS/videos";
import { useNavigate } from "react-router-dom";

// Keyframes for glow effect
const glowAnimation = keyframes`
  0% { box-shadow: 0 0 5px rgba(0, 153, 0, 0.2); }
  50% { box-shadow: 0 0 20px rgba(0, 153, 0, 0.4); }
  100% { box-shadow: 0 0 5px rgba(0, 153, 0, 0.2); }
`;

// Keyframes for floating effect
const floatAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

// Styled components
const StyledCard = styled(Card)(({ theme }) => ({
  background: "linear-gradient(135deg, #e6ffe6 0%, #ffffff 50%, #f0f8f0 100%)",
  borderRadius: "15px",
  boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
  border: "1px solid rgba(255, 255, 255, 0.18)",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px) rotate(1deg)",
    boxShadow: "0 12px 40px 0 rgba(31, 38, 135, 0.37)",
    animation: `${glowAnimation} 2s infinite`,
  },
  [theme.breakpoints.down("sm")]: {
    borderRadius: "10px",
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(4),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#009900",
    },
    "&:hover fieldset": {
      borderColor: "#00cc00",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#00cc00",
    },
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
  "& .MuiInputLabel-root": {
    color: "#009900",
    "&.Mui-focused": {
      color: "#00cc00",
    },
  },
  "& .MuiInputBase-input": {
    color: "black",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: "bold",
  padding: "10px 20px",
  borderRadius: "50px",
  transition: "all 0.3s ease",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 6px 8px rgba(0, 0, 0, 0.2)",
  },
}));

const validationSchema = Yup.object({
  name: Yup.string().required("Tag name is required"),
});

const CreateTagForm = () => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const response = await createVideoTag(values);
        if (response) {
          toast.success("Category added successfully!");
          navigate('/admin/tags')
        }
        resetForm();
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to add category. Please try again.");
      }
      setSubmitting(false);
    },
  });

  const handleCancel = () => {
    formik.resetForm();
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        mt: { md: 20, xs: 4, sm: 8 },
        mb: 4,
        px: { xs: 2, sm: 3 },
      }}
    >
      <StyledCard>
        <StyledCardContent>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            align="center"
            sx={{
              color: "#009900",
              fontWeight: "bold",
              marginBottom: 4,
              fontSize: { xs: "1.5rem", sm: "2rem" },
              textShadow: "2px 2px 4px rgba(0,0,0,0.1)",
              animation: `${floatAnimation} 3s ease-in-out infinite`,
            }}
          >
            Add New Category
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Box display="flex" flexDirection="column" gap={3}>
              <StyledTextField
                fullWidth
                id="category"
                name="name"
                label="Add New Tag"
                variant="outlined"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.name && Boolean(formik.errors.name)
                }
                helperText={formik.touched.name && formik.errors.name}
                InputProps={{
                  startAdornment: (
                    <LabelIcon sx={{ color: "#009900", mr: 1 }} />
                  ),
                }}
                sx={{
                  "& .MuiInputLabel-root": {
                    fontSize: { xs: "0.875rem", sm: "1rem" },
                  },
                  "& .MuiInputBase-input": {
                    fontSize: { xs: "0.875rem", sm: "1rem" },
                  },
                  "& .MuiOutlinedInput-root": {
                    transition: "transform 0.2s, box-shadow 0.2s",
                    "&:hover": {
                      transform: "scale(1.02)",
                      boxShadow: "0 4px 8px rgba(0, 153, 0, 0.2)",
                    },
                  },
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 2,
                  mt: 2,
                }}
              >
                <StyledButton
                  type="submit"
                  variant="contained"
                  disabled={formik.isSubmitting}
                  sx={{
                    backgroundColor: "#009900",
                    color: "#ffffff",
                    "&:hover": {
                      backgroundColor: "#00cc00",
                    },
                  }}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  {formik.isSubmitting ? "Submitting..." : "Add Tag"}
                </StyledButton>
                <StyledButton
                  type="button"
                  variant="outlined"
                  onClick={handleCancel}
                  sx={{
                    borderColor: "#ff4444",
                    color: "#ff4444",
                    "&:hover": {
                      backgroundColor: "#fff0f0",
                      borderColor: "#ff6666",
                    },
                  }}
                  startIcon={<CancelIcon />}
                >
                  Cancel
                </StyledButton>
              </Box>
            </Box>
          </form>
        </StyledCardContent>
      </StyledCard>
    </Container>
  );
};

export default CreateTagForm;
