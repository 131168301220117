import React from "react";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { IconButton } from "@mui/material";

export default function NotificationButton({
  setOpenNotifyModal,
  unReadCount,
}) {
  return (
    <IconButton size="large" aria-label="show 17 new notifications"  onClick={() => {setOpenNotifyModal(true)}}>
      <Badge badgeContent={unReadCount} color="error">
        <NotificationsIcon sx={{ color: "#fff"}}/>
      </Badge>
    </IconButton>
  );
}
