import React, { useState, useEffect } from "react";
import { CardComponent } from "./Card";
import {
  Box,
  Grid,
  Container,
  useTheme,
  Typography,
  Paper,
  Fade,
  Zoom,
  Button,
} from "@mui/material";
import { useSearch } from "./context/SearchContext";
import Skeleton from "@mui/material/Skeleton";
import { fetchVideos } from "./APIS/videos";
import { useQuery } from "react-query";
import { Favorite, VideoLibrary, Search } from "@mui/icons-material";
import { motion } from "framer-motion";

const MotionGrid = motion(Grid);
const MotionPaper = motion(Paper);

export const FavoriteVideos = () => {
  const { searchQuery } = useSearch();
  const theme = useTheme();

  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: videos,
    isLoading,
    refetch,
  } = useQuery(
    ["videos", currentPage, itemsPerPage],
    () => fetchVideos(currentPage, itemsPerPage),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: currentPage !== undefined,
    }
  );

  const sortedVideos = videos?.videos?.sort(
    (a, b) => new Date(b.createdat) - new Date(a.createdat)
  );

  const [loadingSkeleton, setLoadingSkeleton] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoadingSkeleton(false);
    }, 2000);
  }, []);

  const FavoriteVideos = sortedVideos?.filter(
    (video) => video.isFavorite === true
  );

  const filteredVideos =
    FavoriteVideos &&
    FavoriteVideos?.filter((video) =>
      video?.name.toLowerCase().includes(searchQuery.toLowerCase())
    ).filter((video) => video?.isFavorite === true);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",

        minHeight: "100vh",
        pt: 4,
        pb: 8,
      }}
    >
      <Container maxWidth="xl">
        <MotionPaper
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          elevation={3}
          sx={{
            p: 3,
            mb: 4,
            borderRadius: 2,
            background:
              theme.palette.mode === "dark"
                ? "linear-gradient(135deg, rgba(0,100,0,0.2) 0%, rgba(50,205,50,0.2) 100%)"
                : "linear-gradient(135deg, rgba(0,100,0,0.1) 0%, rgba(50,205,50,0.1) 100%)",
            backdropFilter: "blur(10px)",
            border: `1px solid ${
              theme.palette.mode === "dark"
                ? "rgba(255,255,255,0.1)"
                : "rgba(0,0,0,0.1)"
            }`,
          }}
        >
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{
              fontWeight: "bold",
              color: theme.palette.mode === "dark" ? "#fff" : "#006400",
              textShadow: "2px 2px 4px rgba(0,0,0,0.1)",
            }}
          >
            <Favorite
              sx={{ mr: 1, verticalAlign: "middle", color: "#FFD700" }}
            />
            Your Favorite Videos
          </Typography>
        </MotionPaper>

        <MotionGrid
          container
          spacing={3}
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {!loadingSkeleton ? (
            <>
              {searchQuery ? (
                filteredVideos?.length > 0 ? (
                  filteredVideos?.map((video) => (
                    <MotionGrid
                      item
                      key={video?.id}
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      variants={itemVariants}
                    >
                      <Zoom in={true} style={{ transitionDelay: "100ms" }}>
                        <div>
                          <CardComponent module={video} refetch={refetch} />
                        </div>
                      </Zoom>
                    </MotionGrid>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <Fade in={true}>
                      <Paper
                        elevation={3}
                        sx={{
                          p: 3,
                          borderRadius: 2,
                          background:
                            theme.palette.mode === "dark"
                              ? "linear-gradient(135deg, rgba(255,215,0,0.1) 0%, rgba(255,255,0,0.1) 100%)"
                              : "linear-gradient(135deg, rgba(255,215,0,0.2) 0%, rgba(255,255,0,0.2) 100%)",
                          textAlign: "center",
                        }}
                      >
                        <Search
                          sx={{ fontSize: 60, color: "#FFD700", mb: 2 }}
                        />
                        <Typography
                          variant="h5"
                          sx={{
                            color:
                              theme.palette.mode === "dark"
                                ? "#fff"
                                : "#006400",
                          }}
                        >
                          No videos found matching your search
                        </Typography>
                      </Paper>
                    </Fade>
                  </Grid>
                )
              ) : FavoriteVideos?.length > 0 ? (
                FavoriteVideos?.map((video) => (
                  <MotionGrid
                    item
                    key={video?.id}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    variants={itemVariants}
                  >
                    <Zoom in={true} style={{ transitionDelay: "100ms" }}>
                      <div>
                        <CardComponent module={video} refetch={refetch} />
                      </div>
                    </Zoom>
                  </MotionGrid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Fade in={true}>
                    <Paper
                      elevation={3}
                      sx={{
                        p: 5,
                        borderRadius: 2,
                        background:
                          theme.palette.mode === "dark"
                            ? "linear-gradient(135deg, rgba(0,100,0,0.2) 0%, rgba(50,205,50,0.2) 100%)"
                            : "linear-gradient(135deg, rgba(0,100,0,0.1) 0%, rgba(50,205,50,0.1) 100%)",
                        textAlign: "center",
                      }}
                    >
                      <VideoLibrary
                        sx={{ fontSize: 100, color: "#32CD32", mb: 2 }}
                      />
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: "bold",
                          mb: 2,
                          color:
                            theme.palette.mode === "dark" ? "#fff" : "#006400",
                        }}
                      >
                        No videos added to favorites yet
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          mb: 3,
                          color:
                            theme.palette.mode === "dark" ? "#ccc" : "#333",
                        }}
                      >
                        Start exploring and add some videos to your favorites!
                      </Typography>
                      <Button
                        variant="contained"
                        size="large"
                        sx={{
                          background:
                            "linear-gradient(135deg, #FFD700 0%, #FFA500 100%)",
                          color: "#000",
                          "&:hover": {
                            background:
                              "linear-gradient(135deg, #FFA500 0%, #FFD700 100%)",
                          },
                        }}
                      >
                        Explore Videos
                      </Button>
                    </Paper>
                  </Fade>
                </Grid>
              )}
            </>
          ) : (
            <>
              {[...Array(8)].map((_, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={200}
                    sx={{
                      borderRadius: 2,
                      mb: 1,
                      bgcolor:
                        theme.palette.mode === "dark"
                          ? "rgba(255,255,255,0.1)"
                          : "rgba(0,0,0,0.1)",
                    }}
                  />
                  <Skeleton
                    variant="text"
                    width="80%"
                    height={24}
                    sx={{
                      bgcolor:
                        theme.palette.mode === "dark"
                          ? "rgba(255,255,255,0.1)"
                          : "rgba(0,0,0,0.1)",
                    }}
                  />
                  <Skeleton
                    variant="text"
                    width="60%"
                    height={20}
                    sx={{
                      bgcolor:
                        theme.palette.mode === "dark"
                          ? "rgba(255,255,255,0.1)"
                          : "rgba(0,0,0,0.1)",
                    }}
                  />
                </Grid>
              ))}
            </>
          )}
        </MotionGrid>
      </Container>
    </Box>
  );
};
