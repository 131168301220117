import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Typography } from "@mui/material";

export const WarningModal = ({
  content,
  openModal,
  handleClose,
  handleDelete,
}) => {
  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title" >
        <Typography variant="h5" style={{ color: "#c62828" }}>Warning!</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
        variant="outlined"
          sx={{
            textTransform: "none",
            textColor: "text.primary",
            color: "#009900",
          }}
          onClick={handleClose}
        >
          No
        </Button>
        <Button
          onClick={handleDelete}
          variant="contained"
          color="success"
          sx={{
            textTransform: "none",
            textColor: "text.primary",
            color: "#fff",
          }}
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
