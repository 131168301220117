export default function ProcessNotificationData(dummyNotifications) {
  const notifications = dummyNotifications;
  let storedData = localStorage.getItem("messages");
  storedData = storedData ? JSON.parse(storedData) : [];

  const updatedData = [];
  const notificationsToDelete = [];
  const idsToDeleteFromReadNotifications = [];

  const readNotificationsStr = localStorage.getItem("readNotifications");
  const readNotifications = readNotificationsStr
    ? JSON.parse(readNotificationsStr)
    : [];

  notifications.forEach((apiMessage) => {
    const existingMessage = storedData.find(
      (message) => message._id === apiMessage._id
    );

    if (!existingMessage) {
      apiMessage.read = false;
      storedData.push(apiMessage);
      updatedData.push({
        message: apiMessage.message,
        id: apiMessage._id,
        createdAt: apiMessage.createdAt,
      });
    } else {
      if (existingMessage.message !== apiMessage.message) {
        existingMessage.message = apiMessage.message;
      }
    }
  });

  storedData.forEach((storedMessage) => {
    const existingMessage = notifications.find(
      (apiMessage) => apiMessage._id === storedMessage._id
    );
    if (!existingMessage) {
      notificationsToDelete.push(storedMessage._id);

      if (readNotifications.includes(storedMessage._id)) {
        idsToDeleteFromReadNotifications.push(storedMessage._id);
      }
    }
  });

  storedData = storedData.filter(
    (message) => !notificationsToDelete.includes(message._id)
  );

  const updatedReadNotifications = readNotifications.filter(
    (id) => !idsToDeleteFromReadNotifications.includes(id)
  );

  localStorage.setItem(
    "readNotifications",
    JSON.stringify(updatedReadNotifications)
  );
  localStorage.setItem("messages", JSON.stringify(storedData));

  return updatedData;
}
