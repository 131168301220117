import axios from "axios";

// Set baseURL conditionally
const baseURL = process.env.NODE_ENV === 'development' ?
    "https://backend.islaminfotube.com" :
    "https://backend.islaminfotube.com";

// Create a reusable axios instance
const axiosInstance = axios.create({
    baseURL: baseURL
});

// Request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        // Set default headers for all requests
        config.headers['Content-Type'] = 'application/json';
        const token = localStorage.getItem('authToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const axiosInstanceUnauth = axios.create({
    baseURL: baseURL
}); 
axiosInstanceUnauth.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor
axiosInstance.interceptors.response.use(
    (response) => {
        const data = response.data;
        if (data.hasOwnProperty('AccessToken')) {
            response.config.headers.Authorization = `Bearer ${data.AccessToken}`;
        }
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const axiosInstanceForFile = axios.create({
    baseURL: baseURL
});

// Request interceptor for axios File
axiosInstanceForFile.interceptors.request.use(
    (config) => {
        // Set default headers for all requests
        config.headers['Content-Type'] = 'multipart/form-data';
        const token = localStorage.getItem('authToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor for axios File
axiosInstanceForFile.interceptors.response.use(
    (response) => {
        const data = response.data;
        if (data.hasOwnProperty('AccessToken')) {
            response.config.headers.Authorization = `Bearer ${data.AccessToken}`;
        }
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export { axiosInstance, axiosInstanceForFile, axiosInstanceUnauth };
