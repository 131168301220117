import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/system";
import { buttonStyles } from "../authorization/Login";
import { axiosInstance } from "../../AxiosInstance";
import { toast } from "react-toastify";
import { Box } from "@mui/material";

const Title = styled("h2")`
  margin-bottom: 16px;
  text-align: center;
  color: #009900;
`;

const InputRow = styled("div")`
  display: flex;
  gap: 8px;
  width: 100%;
`;

const InputField = styled(TextField)`
  flex: 1;
`;

export const UpdateProfile = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [userId, SetUserId] = useState(user?.id);
  const [updateFirstName, setUpdateFirstName] = useState(user?.firstname);
  const [updateLastName, setUpdateLastName] = useState(user?.lastname);
  const [updatePhone, setUpdatePhone] = useState(user?.phone);
  const [updateEmail, setUpdateEmail] = useState(user?.email);

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    const profileData = {
      id: userId,
      firstname: updateFirstName,
      lastname: updateLastName,
      phone: updatePhone,
    };
    axiosInstance
      .post("/users/update", JSON.stringify(profileData))
      .then((response) => {
        const resp = response.data;
        if (resp?.User) {
          localStorage.setItem("user", JSON.stringify(response.data.User));
        }
        toast.success("Profile has been successfully updated.", "success");
      })
      .catch((error) => {
        const errorResponse = error?.response?.data;
        if (errorResponse) {
          toast.error(errorResponse.message);
          return;
        }
        toast.error("Error in updateing profile");
      });
  };
  return (
      <Card>
        <CardContent>
          <Title>Update Your Profile</Title>
          <InputRow>
            <InputField
              label="FirstName"
              fullWidth
              margin="normal"
              value={updateFirstName}
              onChange={(e) => setUpdateFirstName(e.target.value)}
            />
            <InputField
              label="LastName"
              fullWidth
              margin="normal"
              value={updateLastName}
              onChange={(e) => setUpdateLastName(e.target.value)}
            />
          </InputRow>
          <InputRow>
          <InputField
            label="Email"
            type="email"
            fullWidth
            margin="normal"
            value={updateEmail}
            onChange={(e) => setUpdateEmail(e.target.value)}
          />
          <InputField
            label="Phone"
            fullWidth
            margin="normal"
            value={updatePhone}
            onChange={(e) => setUpdatePhone(e.target.value)}
          />
          </InputRow>
          {/* Add file input for image upload */}
          <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "right"}}>
          <Button
            // fullWidth
            variant="contained"
            sx={{ marginTop: "14px", ...buttonStyles }}
            onClick={handleUpdateProfile}
          >
            Update Profile
          </Button>
          </Box>
        </CardContent>
      </Card>
  );
};
