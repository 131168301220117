import React, { useState, useEffect } from "react";
import { Header } from "./Header";
import { Sidebar } from "./Sidebar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

export const LayoutContainer = ({ children }) => {
  const [open, setOpen] = useState(true);

  const storedMode = localStorage.getItem("themeMode");
  const [mode, setMode] = useState(storedMode || "light");

  const toggleColorMode = () => {
    const newMode = mode === "light" ? "dark" : "light";
    setMode(newMode);
    localStorage.setItem("themeMode", newMode);
  };

  const theme = createTheme({
    palette: {
      mode: mode,
    },
  });

  const handleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 900) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
        <Header
          handleDrawer={handleDrawer}
          open={open}
          toggleColorMode={toggleColorMode}
        />
        <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
          <Sidebar open={open} handleDrawer={handleDrawer} />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              width: open ? 'calc(100% - 240px)' : '100%', // Adjust width based on sidebar state
              transition: 'width 0.3s',
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
                boxSizing: 'border-box',
                width: '100%',
                minWidth: 0,
                mt: 10
              }}
            >
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default LayoutContainer;