import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import { UpdateProfile } from "./UpdateProfile";
import AccountSettings from "./AccountSettings";
import { Container } from "@mui/material";

const StyledBox = styled(Box)`
  padding: 10px;
  display: "flex";
  flex-direction: column;
  align-items: center;
`;

const StyledTabs = styled(Tabs)`
  margin-bottom: 0px;
`;

const StyledTab = styled(Tab)`
  color: #009900 !important;
  font-weight: bold;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const Settings = () => {
  const [tabValue, setTabValue] = useState(1);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Container>
      <StyledBox>
        <StyledTabs value={tabValue} onChange={handleTabChange} centered>
          <StyledTab label="Account Settings" />
          <StyledTab label="Update Profile" />
        </StyledTabs>
        <TabPanel value={tabValue} index={0}>
          <AccountSettings />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <UpdateProfile />
        </TabPanel>
      </StyledBox>
    </Container>
  );
};

const TabPanel = ({ children, value, index }) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ marginTop: "50px" }}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
};
