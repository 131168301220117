import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  Grid,
} from "@mui/material";
import moment from "moment";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Slide from "@mui/material/Slide";
import styled from "@mui/material/styles/styled";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} timeout={1000} />;
});

export function timeAgo(timestamp) {
  return moment(timestamp).fromNow();
}

const modalPaperStyles = {
  position: "absolute",
  right: 0,
  top: 30,
  width: "30%",
};

const ModalPaper = styled("div")({
  ...modalPaperStyles,
});

export const NotificationModal = ({ refresh, openModal, handleClose }) => {
  const [readNotifications, setReadNotifications] = useState([]);
  //  localStorage.removeItem("messages");
  //   localStorage.removeItem("readNotifications");

  const getNotificationMessageStyle = (notification) => ({
    fontWeight: readNotifications.includes(notification._id) ? "400" : "700",
  });

  const storedNotificationsStr = localStorage.getItem("messages");
  const storedNotifications = storedNotificationsStr
    ? JSON.parse(storedNotificationsStr)
    : [];
  const content = [...storedNotifications].sort(
    (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
  );

  const [showNotificationContent, setShowNotificationContent] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState({});

  useEffect(() => {
    const storedReadNotificationsStr =
      localStorage.getItem("readNotifications");
    const storedReadNotifications = storedReadNotificationsStr
      ? JSON.parse(localStorage.getItem("readNotifications"))
      : [];
    setReadNotifications(storedReadNotifications);
  }, []);

  const markAllAsRead = () => {
    const allNotificationIds = content.map((notification) => notification._id);
    const unreadIds = allNotificationIds.filter(
      (id) => !readNotifications.includes(id)
    );

    if (unreadIds.length > 0) {
      const updatedReadNotifications = [...readNotifications, ...unreadIds];
      setReadNotifications(updatedReadNotifications);

      localStorage.setItem(
        "readNotifications",
        JSON.stringify(updatedReadNotifications)
      );
      refresh();
    }
  };

  const dialogContent = showNotificationContent ? (
    <Paper
      sx={{
        height: "auto",
        padding: "16px",
        backgroundColor: "#f5f5f5",
        borderRadius: "16px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Grid container spacing={2}>
        <Grid item md={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <a
              onClick={(event) => {
                event.preventDefault();
                setShowNotificationContent(false);
              }}
              className="mybtn customizedbtn"
              style={{ background: "transparent" }}
            >
              <ArrowBack style={{color: "#009900"}} />
            </a>
            <Typography variant="body2" color="textSecondary">
              {timeAgo(selectedNotification.createdAt)}
            </Typography>
          </div>
        </Grid>
        <Grid item md={12}>
          <Typography variant="body1" style={{ paddingLeft: "18px" }}>
            {selectedNotification.message}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  ) : (
    openModal &&
    (content.length > 0 ? (
      <TableContainer>
        <Table>
          <TableBody>
            {content?.map((notification) => (
              <TableRow
                key={notification._id}
                onClick={() => {
                  setSelectedNotification(notification);
                  setShowNotificationContent(true);
                }}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#f0f0f0",
                  },
                }}
              >
                <TableCell>
                  <div>
                    <Typography
                      variant="body1"
                      style={getNotificationMessageStyle(notification)}
                    >
                      {notification.message.slice(0, 30)}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="body2" color="textSecondary">
                      {timeAgo(notification.createdAt)}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell>
                  {!readNotifications.includes(notification._id) && (
                    <FiberManualRecordIcon
                      style={{ color: "#00cc33" }}
                      fontSize="small"
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <div style={{ 
        backgroundColor: "#f5f5f5",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)",
        margin: "16px auto",
        maxWidth: "80%" }}>
        <Typography variant="body1">
          You have no new notifications at the moment.
        </Typography>
      </div>
    ))
  );

  const dialogActions = showNotificationContent ? (
    <>
      {!readNotifications.includes(selectedNotification._id) ? (
        <Button
          style={{ color: "#009900" }}
          onClick={() => {
            if (!readNotifications.includes(selectedNotification._id)) {
              setReadNotifications((prevReadNotifications) => [
                ...prevReadNotifications,
                selectedNotification._id,
              ]);

              localStorage.setItem(
                "readNotifications",
                JSON.stringify([...readNotifications, selectedNotification._id])
              );
              refresh();
            }
          }}
        >
          Mark As Read
        </Button>
      ) : (
        <Button
          style={{ color: "#009900" }}
          onClick={() => {
            if (readNotifications.includes(selectedNotification._id)) {
              setReadNotifications((prevReadNotifications) =>
                prevReadNotifications.filter(
                  (id) => id !== selectedNotification._id
                )
              );

              localStorage.setItem(
                "readNotifications",
                JSON.stringify(
                  readNotifications.filter(
                    (id) => id !== selectedNotification._id
                  )
                )
              );
              refresh();
            }
          }}
        >
          Mark as unread
        </Button>
      )}
    </>
  ) : (
    content.length > 0 && (
      <Button style={{ color: "#009900" }} onClick={markAllAsRead}>
        Mark all as read
      </Button>
    )
  );

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      sx={{
        "& .MuiDialog-paper": modalPaperStyles,
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {!showNotificationContent && "All Notifications"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          handleClose();
          setShowNotificationContent(false);
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>{dialogContent}</DialogContent>

      <DialogActions>{dialogActions}</DialogActions>
    </Dialog>
  );
}
